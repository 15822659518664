import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import CSRImage from '../assets/icon.png';
import { Stack } from "@mui/material";
import ODBlogo from "../assets/ODB.png";
import IONTUlogo from "../assets/logo_sticker_5cm.png";
export default function Footer() {
  const year = new Date().getUTCFullYear();

  return (
    <>
      <Box
        sx={{
          py: 1.5,
          borderBottom: "1px solid",
          borderColor: "#E4E4E4",
          backgroundColor: "#fafafa",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "start",
            maxWidth: "1400px",
            margin: "0 auto",
            px: { xs: 2, md: 6 },
          }}
        >
          <img src={CSRImage} alt={'CSR_image'} style={{height:'80px', paddingTop:'20px'}}/>
          <br/>
          
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              marginBottom: "20px",
              letterSpacing: "0.5px",
              fontFamily: "'Noto Sans TC'",
            }}
          >
            國立臺灣大學海洋研究所
            <br />
            10617 臺北市大安區羅斯福路四段一號
            <br />
            <br />
            電話: +886-2-2363-6450#117
            <br />
            Email: yehtc@ntu.edu.tw
            <br />
          </Typography>
          <Stack direction="row" spacing={4} sx={{paddingBottom:'5px'}}>
            <a href='https://www.odb.ntu.edu.tw/' target="_blank" rel="noreferrer">
              <Avatar
              alt="ODBlogo"
              src={ODBlogo}
              sx={{ width: 48, height: 48 }}
              />  
            </a>
            <a href='https://www.oc.ntu.edu.tw/' target="_blank" rel="noreferrer">
              <Avatar
              alt="IONTUlogo"
              src={IONTUlogo}
              sx={{ width: 48, height: 48 }}
              />
            </a>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          py: "10px",
          textAlign: "center",
          fontFamily: "'Poppins'",
        }}
      >
        <Typography variant="caption">
          Copyright ©{year} 海洋學門資料庫-Ocean Data Bank (ODB). All Rights
          Reserved
        </Typography>
      </Box>

    </>
  );
}
